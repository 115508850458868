.modal-content{
  color: #282c34;

  .dropzone{
    border: 1px solid #282c34;
    border-radius: 10px;
    text-align: center;
    padding: 15px;
    .d-50{
      width: 50px;
      height: 50px;
    }
    .d-60{
      width: 60px;
      height: 60px;
    }
  }

  .image-container{
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    .item{
      width: 130px;
      height: 130px;
      overflow: hidden;
      margin: 10px;
      position: relative;
      display: flex;
      align-items: center;
      .btn-link{
        padding: 0;
        margin:0;
        border: none;
      }
      img{
        width: 100%;
      }
      .btn-remove{
        position: absolute;
        top: 2px;
        right: 2px;
        background-color: #DC3545FF;
        border: none;
        border-radius: 100%;
        color: #fff;
        padding: 3px;
        display: none;
        transition: all 200ms ease-in-out;
        .close-icon{
          width: 18px;
          height: 18px;
        }
      }
      &:hover{
        .btn-remove{
          display: block;
        }
      }
    }
  }
}