.main-layout{
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  background: url("../images/stadion_2.jpg") no-repeat;
  background-size: 100% 100%;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  color: #fff;


  .title{
    text-align: center;
    font-size: 2.7rem;
    margin: 20px 0 10px;
    text-transform: uppercase;
  }
  .time{
    display: block;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 10px 0;
    font-size: 5.7rem;
    line-height: 6rem;
    background: url("../images/time_bg.png") no-repeat;
    background-size: 100% 100%;
  }
  .extra-time{
    display: block;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 9px 0;
    font-size: 3.2rem;
    line-height: 2.8rem;
    background: url("../images/extra_time_bg.png") no-repeat;
    background-size: 100% 100%;
  }
  .subtitle{
    text-align: center;
    font-size: 1.55rem;
    margin-top: 15px;
  }

  .score-wrapper{
    position: absolute;
    left: 20px;
    top: 60px;
    right: 20px;

    .teams-container{
      display: flex;
      justify-content: space-between;
      .team-wrapper{
        width: 500px;
        height: 500px;
        background: url("../images/momcad_logo_overlay.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
      }
      .team{
        border-radius: 100%;
        overflow: hidden;
        width: 312px;
        height: 312px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: flex;
        align-items: center;
        img{width: 100%;}
      }
    }

    section.score-main{
      display: flex;
      justify-content: center;

      .score{
        font-family: "Arial", sans-serif;
        font-size: 16rem;
        line-height: 10rem;
        letter-spacing: 30px;
        display: flex;
        margin: 0 80px;
        span{
          font-family: Arial, sans-serif;
          font-size: 9rem;
          padding-bottom: 50px;
          display: block;
        }
      }
      .score-players{
        flex-grow: 1;
        width: 100%;
        ul{
          margin: 0;
          padding: 0;
          list-style-type: none;
          li{
            font-size: 2.2rem;
          }
          &.home li{
            background: url("../images/lopta.png") no-repeat top right;
            background-size: 30px 30px;
            background-position-y: 9px;
            padding-right: 47px;
            text-align: right;
          }
          &.guest li{
            background: url("../images/lopta.png") no-repeat;
            background-size: 30px 30px;
            background-position-y: 9px;
            padding-left: 42px;
          }
        }
      }
    }
  }

  .goal-wrapper{
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    .goal-bg{
      background: url("../images/goal_bg.png") no-repeat;
      background-size: 100% 100%;
      width: 100%;
      height: 100%;
      transform: rotate(-90deg);
      transition: all 350ms ease-in;
    }
    .goal-text{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      background: url("../images/goal_text.png") no-repeat;
      background-size: 100% 100%;
      width: 15%;
      height: 15%;
      transition: all 500ms ease-in;
    }
    &.active{
      .goal-bg{
        transform: rotate(0deg);
      }
      .goal-text{
        width: 75%;
        height: 75%;
      }
    }
  }

  .goal-player-wrapper{
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    text-align: center;
    .goal-title{
      font-size: 13rem;
      line-height: 13rem;
      margin-bottom: 50px;
    }
    .goal-player{
      font-size: 11rem;
      line-height: 11rem;
    }
  }
}