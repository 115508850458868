.home-page{
  .time{
    text-align: center;
    font-size: 2.2rem;
    margin-top: 20px;
  }
  .extra-time{
    text-align: center;
    font-size: 1.2rem;
    color: #e84444;
  }
  .score{
    margin: 40px 0 30px;
    text-align: center;
    font-size: 3.5rem;
  }

  .new-score{
    text-align: center;
    .btn{
      width: 200px !important;
    }
  }

  .goal-scores{
    margin: 35px 0 20px;
    display: flex;
    flex-direction: column;
    .item{
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      .name{
        flex-grow: 1;
        padding-right: 15px;
      }
      .guest{
        text-align: right;
      }
    }
  }
}