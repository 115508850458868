.settings-page{
  .navbar{
    margin-bottom: 35px;
  }

  .card{
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    background: #4a4d56;
    .card-header{
      font-size: 1.3rem;
      font-weight: bold;
      text-align: center;
    }
    .btn-container{
      margin-top: 25px;
    }
  }
  h3{
    font-size: 1.25rem;
    margin: 12px 0 0;
  }
  .logo-container{
    padding: 0;
    margin: 0;
    background: 0;
    position: relative;
    border: none;
    margin-bottom: 15px;
    width: 180px;
    height: 180px;
    .logo{
      background-color: #fff;
      border-radius: 100%;
      width: 122px;
      height: 122px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      overflow: hidden;
      z-index: 100;
      display: flex;
      align-items: center;
      img{
        width: 100%;
      }
    }
    .logo-overlay{
      background: url("../images/momcad_logo_overlay.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 100%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}